<template>
  <div class="oder_wrapper">
    <div>
      <h2 class="flexa">订单管理</h2>
    </div>
    <header class="flex">
      <div class="flexs">
        <p class="marR10 sd">状态：</p>
        <el-select
            v-model="params.orderStatus"
            placeholder="全部状态"
            size="mini"
            clearable
            class="select_input img w150 marR20"
        >
          <el-option label="待处理" value="0"></el-option>
          <el-option label="已处理" value="1"></el-option>
          <el-option label="交易完成" value="2"></el-option>
        </el-select>
      </div>
      <el-input
          v-model="params.orderName"
          placeholder="请输入联系人"
          size="mini"
          clearable
      ></el-input>
      <el-input
          v-model="params.orderTel"
          placeholder="请输入联系人方式"
          size="mini"
          clearable
      ></el-input>
      <el-button type="primary" size="mini"
      >查询
      </el-button
      >
    </header>
    <section>
      <el-table
          :data="tableData"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="封面图">
          <template slot-scope="scope">
            <img :src="scope.row.productImage" alt="" class="imgd"/>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" width="200">
        </el-table-column>
        <el-table-column prop="productPrice" label="价格"></el-table-column>
        <el-table-column prop="productNums" label="数量" width="60"></el-table-column>
        <el-table-column prop="orderName" label="姓名"></el-table-column>
        <el-table-column prop="orderTel" label="手机号"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <p v-if="scope.row.orderStatus == 0" class="p1">待处理</p>
            <p v-if="scope.row.orderStatus == 1" class="p2">已处理</p>
            <p v-if="scope.row.orderStatus == 2" class="p3">交易完成</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="orderDetail(scope.row.id)"
            ><p class="p4">详情</p></el-button
            >
            <el-button type="text" @click="editProductOrder(scope.row.id,scope.row.orderStatus)"
            ><p class="p4">修改状态</p></el-button
            >
            <el-button type="text" @click="deleteMyProducts(scope.row.id)"
            ><p class="p4">删除</p></el-button>
          </template>
        </el-table-column>
      </el-table>
      <status
          type="post"
          des="您还未发布产品~"
          v-if="this.tableData == null || this.tableData.length <= 0"
      ></status>
    </section>
    <div class="pages">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="prev, pager, next, total, jumper"
          :total="params.total"
      ></el-pagination>
    </div>
    <el-dialog
        title="订单详情"
        :visible.sync="isShow"
        width="30%"
        :before-close="handleClose">
        <el-form class="dialog_form">
          <el-form-item label="订购数量：">{{orDetail.productNums}}</el-form-item>
          <el-form-item label="订购人姓名：">{{orDetail.orderName}}</el-form-item>
          <el-form-item label="订购人电话：">{{orDetail.orderTel}}</el-form-item>
          <el-form-item label="收货地址：" v-if="orDetail.dictJson">{{orDetail.dictJson.orderProvince+orDetail.dictJson.orderCity+orDetail.dictJson.orderThreeCity}}</el-form-item>
          <el-form-item label="详细地址：">{{orDetail.orderAddr}}</el-form-item>
        </el-form>
      <div class="footer" slot="footer">
        <el-button type="primary" @click="handleClose">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 9,
        orderStatus: '',
        orderTel: '',
        orderName: "",
        total: 0,
      },
      tableData: [],
      orDetail:[],
      isShow:false
    };
  },
  watch:{
    params: {
      handler(newName, oldName) {
        this.myProductOrderList();
      },
      deep: true,
    },
  },
  methods: {
    async myProductOrderList() {
      let res = await this.$axios.post('/api/app-jycy-henan/henan/products/order/list', this.params);
      if (res.data.success){
        this.tableData = res.data.data.list;
        this.params.total = res.data.data.total;
      }
    },
    async orderDetail(id){
      let res = await this.$axios.get('/api/app-jycy-henan/henan/products/order/detail/'+id);
      if (res.data.success){
        this.orDetail = res.data.data;
        this.isShow = true;
      }
    },
    editProductOrder(id, status) {
      if (status == 2) {
        this.$message.warning("您的交易已完成，不能再进行修改！");
        return;
      } else {
        this.$confirm("确定要修改当前操作吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              this.$axios.get('/api/app-jycy-henan/henan/products/order/update/' + id).then(res => {
                if (res.data.success) {
                  this.myProductOrderList();
                  this.$message.success(res.data.msg);
                }
              })
            })
            .catch((err) => {
              this.$message.warning('已取消')
            });
      }
    },
    deleteMyProducts(id) {
      this.$axios.get('/api/app-jycy-henan/henan/products/order/delete/' + id).then(res => {
        if (res.data.success) {
          this.$confirm("确定删该产品吗?一旦删除不可恢复", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
              .then(() => {
                this.$message.success(res.data.msg);
                this.myProductOrderList();
              })
              .catch((err) => {
                console.log(err);
              });
        }
      })
    },
    handleClose(){
      this.isShow = false;
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    //分页
    handleCurrentChange: function (val) {
      this.params.pageNum = val;
      window.scrollTo(0, 400);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.myProductOrderList();
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/henan/produce";

/depp/ .el-table th > .cell，.el-table .cell {
  text-align: center !important;
}
.dialog_form{
  padding: 0 20px;
}
.footer{
  text-align: center;
 .el-button{
    width: 200px;
  }
}
</style>
